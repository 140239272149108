import React from 'react';
import appData from '../../../models/ReddworldData'
import {Field, FieldArray, Form, Formik} from 'formik'
import {FormikDatePicker} from "../../input_elements/datepicker/FormikDatePicker";
import {FormikSlider} from "../../input_elements/slider/FormikSlider";
import {FormikGenderSlider} from "../../input_elements/slider/FormikGenderSlider";
import {TextInput} from '../../input_elements/text_input/TextInput'
import * as Yup from 'yup'
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import {RWRoundButton} from "../../input_elements/buttons/Buttons";
import withStyles from "@material-ui/core/styles/withStyles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails";
import {Minus, Plus, SaveIcon} from "../../icons/GeneralIcons";
import {IconGenderFemale, IconGenderFemaleWhite, IconGenderMale, IconGenderMaleWhite} from "../../icons/GenderIcons";
import {FormikTshirtSlider} from "../../input_elements/slider/FormikTshirtSlider";
import {FormikSchoolSlider} from "../../input_elements/slider/FormikSchoolSlider";
import {FormikExperienceSlider} from "../../input_elements/slider/FormikExperienceSlider";
import {FormikDegreeOfKnowledgeSlider} from "../../input_elements/slider/FormikDegreeOfKnowledgeSlider";
import {FormikGraduationSlider} from "../../input_elements/slider/FormikGraduationSlider";
import {SelectNative} from "../../input_elements/select_native/SelectNative";
import {FormikSwitch} from "../../input_elements/switch/FormikSwitch";
import {makeStyles, Snackbar} from "@material-ui/core";
import {amber, blueGrey, green} from "@material-ui/core/colors";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';

// schema for validation of the data, error messages can be defined here.
// see https://github.com/jquense/yup for more documentation


const ExpansionPanel = withStyles({
    root: {
        //border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        "margin-right": 0,
        "margin-left": 0,
        "margin-top": 0,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: '#ffffff',
        //borderBottom: '1px solid rgba(0, 0, 0, .125)',
        margin: "0 0",
        marginBottom: -1,
        minHeight: 28,
        '&$expanded': {
            margin: '0 0',
            '&:first-child': {
                marginTop: 0,
            },
            '&:last-child': {
                marginBottom: 0,
            },
            '&:before': {
                opacity: 0,
            },
        },
        '&$expanded + &': {
            '&:before': {
                display: 'none',
            },
        },
    },
    content: {
        '&$expanded': {
            //margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
    root: {
        background: "#ffffff",
        padding: theme.spacing(2),
    },
}))(MuiExpansionPanelDetails);


const personalDataSchema = Yup.object().shape({
    firstname: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    lastname: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    date_of_birth: Yup.date()
        .required('Date Of Birth is required')
});

const genderMarks = [
    {
        value: 2,
        label: <IconGenderFemale/>,
    },
    {
        value: 1,
        label: <IconGenderMale/>,
    },
];

function genderValueLabelFormat(value) {
    switch (value) {
        case 1:
            return <IconGenderMaleWhite/>;
        case 2:
            return <IconGenderFemaleWhite/>;
        default:
            return "";
    }
}

const tshirtMarks = [
    {
        value: 1,
        label: "XXS",
    },
    {
        value: 2,
        label: "XS",
    },
    {
        value: 3,
        label: "S",
    },
    {
        value: 4,
        label: "M",
    },
    {
        value: 5,
        label: "L",
    },
    {
        value: 6,
        label: "XL",
    },
    {
        value: 7,
        label: "XXL",
    },
];

function tshirtValueLabelFormat(value) {
    switch (value) {
        case 1:
            return "XXS";
        case 2:
            return "XS";
        case 3:
            return "S";
        case 4:
            return "M";
        case 5:
            return "L";
        case 6:
            return "XL";
        case 7:
            return "XXL";
        default:
            return "bitte auswählen";
    }
}

function experienceValueLabelFormat(value) {
    switch (value) {
        case 0:
            return "keine Erfahrung";
        case 1:
            return "wenig Erfahrung";
        case 2:
            return "mittlere Erfahrung";
        case 3:
            return "viel Erfahrung";
        case 4:
            return "sehr viel Erfahrung";
        default:
            return "bitte auswählen";
    }
}

const experienceMarks = [
    {
        value: 0,
        label: "keine",
    },
    {
        value: 1,
        label: "",
    },
    {
        value: 2,
        label: "",
    },
    {
        value: 3,
        label: "",
    },
    {
        value: 4,
        label: "viel",
    },
];

function degreeOfKnowledgeValueLabelFormat(value) {
    switch (value) {
        case 1:
            return "Grundkenntnis";
        case 2:
            return "sicherer Umgang";
        case 3:
            return "Fließend / Perfekt";
        case 4:
            return "Muttersprache";
        default:
            return "bitte auswählen";
    }
}

const degreeOfKnowledgeMarks = [
    {
        value: 1,
        label: "Grundkenntnis",
    },
    {
        value: 2,
        label: "",
    },
    {
        value: 3,
        label: "",
    },
    {
        value: 4,
        label: "Muttersprache",
    },
];

function academicDegreeValueLabelFormat(value) {
    switch (value) {
        case 1:
            return "M.Sc.";
        case 2:
            return "M.A.";
        case 3:
            return "B.Sc.";
        case 4:
            return "B.A.";
        case 5:
            return "Dipl.";
        case 6:
            return "MBA";
        default:
            return "";
    }
}

const academicDegreeMarks = [
    {
        value: 4,
        label: "B.A.",
    },
    {
        value: 3,
        label: "B.Sc.",
    },
    {
        value: 2,
        label: "M.A.",
    },
    {
        value: 1,
        label: "M.Sc.",
    },
    {
        value: 6,
        label: "MBA",
    },
    {
        value: 5,
        label: "Dipl."
    }
];

const useSavingStyles = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: blueGrey[300],
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

function FeedbackContentWrapper(props) {
    const classes = useSavingStyles();
    const {className, message, onClose, variant, ...other} = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)}/>
                    {message}
        </span>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon}/>
                </IconButton>,
            ]}
            {...other}
        />
    );
}

FeedbackContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

export default function PersonalData() {
    // const savingFeedbackClasses = useSavingStyles();

    const [expanded, setExpanded] = React.useState('panel2');

    const [saving, setSaving] = React.useState(false);
    const [savingSuccess, setSavingSuccess] = React.useState(false);
    const [savingError, setSavingError] = React.useState(false);
    const [savingFeedbackOpen, setSavingFeedbackOpen] = React.useState(true);
    // holds a random variable, for forcing rerendering of the component
    // eslint-disable-next-line no-unused-vars
    const [random, setRandom] = React.useState(null);

    const dateOffset = (24 * 60 * 60 * 1000) * 18 * 365;
    const maxBirthday = new Date();
    maxBirthday.setTime(new Date().getTime() - dateOffset);

    const handlePanelChange = panel => (event, newExpanded) => {
        executeScroll(panel);
        setTimeout(function() {
            setExpanded(newExpanded ? panel : false);
        }, 50);
    };

    function openSavingFeedback() {
        setSavingFeedbackOpen(true);
    }

    function handleSavingFeedbackClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setSavingFeedbackOpen(false);
    }

    // used for scrolling
    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
    const panel1Ref = React.useRef(null);
    const panel2Ref = React.useRef(null);
    const panel3Ref = React.useRef(null);
    const panel4Ref = React.useRef(null);
    const panel5Ref = React.useRef(null);
    const panel6Ref = React.useRef(null);
    const panel7Ref = React.useRef(null);
    const panel8Ref = React.useRef(null);
    const panel9Ref = React.useRef(null);

    function executeScroll(panel) {
        switch(panel) {
            case "panel1":
                scrollToRef(panel1Ref);
                break;
            case "panel2":
                scrollToRef(panel2Ref);
                break;
            case "panel3":
                scrollToRef(panel3Ref);
                break;
            case "panel4":
                scrollToRef(panel4Ref);
                break;
            case "panel5":
                scrollToRef(panel5Ref);
                break;
            case "panel6":
                scrollToRef(panel6Ref);
                break;
            case "panel7":
                scrollToRef(panel7Ref);
                break;
            case "panel8":
                scrollToRef(panel8Ref);
                break;
            case "panel9":
                scrollToRef(panel9Ref);
                break;
            default:
                break;
        }
    }

    return (

        <Formik
            initialValues={appData.user}
            validationSchema={personalDataSchema}

            onSubmit={(userValues, {setSubmitting}) => {
                // on submit of form, update the mobx state tree
                // updating the data to the backend can be done with an event listener on the model (onPatch)
                // console.log("updating personal data", JSON.stringify(userValues));
                setSaving(true);
                appData.setUser(userValues);
                setSavingSuccess(false);
                setSavingError(false);
                openSavingFeedback();
                appData.saveUserDataToBackend().then(function (values) {
                    setSaving(false);
                    setSavingSuccess(true);
                    setRandom(Math.random());
                }, function (values) {
                    setSaving(false);
                    setSavingError(true);
                    setRandom(Math.random());
                });
                // values contains the entire user subtree of the state
                //alert("user updated" + JSON.stringify(userValues, null, 2));
                setSubmitting(false);
                // setTimeout(() => {
                // }, 400);
            }}
            render={({handleChange, handleBlur, values, errors, handleSubmit}) => (
                <Form autoComplete={"off"}>
                    {/*<ExpansionPanel square expanded={expanded === 'panel1'}*/}
                    {/*                onChange={handlePanelChange('panel1')}>*/}
                    {/*    <ExpansionPanelSummary*/}
                    {/*        aria-controls="panel1d-content" id="panel1d-header"*/}
                    {/*    >*/}
                    {/*        <Line/> <Typography className={'BasicData'}>Profil</Typography>*/}
                    {/*    </ExpansionPanelSummary>*/}
                    {/*    <ExpansionPanelDetails>*/}
                    {/*       */}
                    {/*        /!*<Field type="text"p name="date_of_birth" />*!/*/}
                    {/*        /!*{errors.date_of_birth && touched.date_of_birth ? (*!/*/}
                    {/*        /!*    <div>{errors.date_of_birth}</div>*!/*/}
                    {/*        /!*) : null}*!/*/}

                    {/*    </ExpansionPanelDetails>*/}
                    {/*</ExpansionPanel>*/}
                    <h1>Profil</h1>
                    <ExpansionPanel ref={panel2Ref} square expanded={expanded === 'panel2'} onChange={handlePanelChange('panel2')}>
                        <ExpansionPanelSummary aria-controls="panel2d-content" id="panel2dr-header">
                            {expanded === 'panel2' ? <Minus/> : <Plus/>} <Typography className={'PersonalData'}>Persönliche
                            Daten</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    Benutzername: {appData.user.username}<br/>
                                </Grid>
                                <Grid item xs={12}>
                                    <br/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextInput} name="firstname" label="Vorname"
                                           onBlur={handleSubmit}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextInput} name="lastname" label="Nachname"
                                           onBlur={handleSubmit}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextInput} name="mobile" label="Telefonnummer"
                                           onBlur={handleSubmit}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextInput} name="email" label="E-Mail Adresse"
                                           onBlur={handleSubmit}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <Field component={FormikGenderSlider}
                                           name="gender.id"
                                           label="Geschlecht"
                                           defaultValue={values.gender.id}
                                           valueLabelFormat={genderValueLabelFormat}
                                           getAriaValueText={genderValueLabelFormat}
                                           aria-labelledby="continuous-slider"
                                           min={1}
                                           max={2}
                                           valueLabelDisplay="auto"
                                           marks={genderMarks}
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikDatePicker}
                                           disableFuture={true}
                                           name="date_of_birth"
                                           maxDate={maxBirthday}
                                           label="Geburtsdatum" onBlur={handleSubmit}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextInput} name="place_of_birth" label="Geburtsort"
                                           onBlur={handleSubmit}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikSlider} name="service_employee.body_height"
                                           min={140}
                                           max={220}
                                           label="Körpergröße" unit="cm"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel ref={panel3Ref} square expanded={expanded === 'panel3'} onChange={handlePanelChange('panel3')}>
                        <ExpansionPanelSummary aria-controls="panel3d-content" id="panel3d-header">
                            {expanded === 'panel3' ? <Minus/> : <Plus/>} <Typography className={'PersonalData'}>Geographische
                            Daten</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Field component={TextInput} name='address.street' label="Straße"
                                           onBlur={handleSubmit}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextInput} name='address.housingNumber' label="Hausnummer"
                                           onBlur={handleSubmit}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextInput} name='address.zip' label="PLZ" onBlur={handleSubmit}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextInput} name='address.city.city' label="Stadt"
                                           onBlur={handleSubmit}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextInput} name='address.state.state' label="Bundesland"
                                           onBlur={handleSubmit}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextInput} name='address.country.country' label="Land"
                                           onBlur={handleSubmit}/>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel ref={panel4Ref} square expanded={expanded === 'panel4'} onChange={handlePanelChange('panel4')}>
                        <ExpansionPanelSummary aria-controls="panel4d-content" id="panel4d-header">
                            {expanded === 'panel4' ? <Minus/> : <Plus/>} <Typography className={'PersonalData'}>Körperbezogene
                            Daten</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Field component={FormikSlider}
                                           name='service_employee.breast_circumference'
                                           min={80} max={120}
                                           label="Brustumfang"
                                           unit="cm"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikSlider} name='service_employee.waist_size'
                                           min={60} max={120}
                                           label="Taillenumfang" unit="cm"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikSlider}
                                           name='service_employee.seat_circumference'
                                           min={80} max={120}
                                           label="Hüftumfang" unit="cm"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12} id={"hair"}>
                                    {/*<Field component={FormikHairColorSlider} name='service_employee.hairColor.id'*/}
                                    {/*       min={1}*/}
                                    {/*       max={8}*/}
                                    {/*       valueLabelFormat={hairColorValueLabelFormat}*/}
                                    {/*       getAriaValueText={hairColorValueLabelFormat}*/}
                                    {/*       aria-labelledby="discrete-slider-restrict"*/}
                                    {/*       marks={hairColorMarks}*/}
                                    {/*       onBlur={handleSubmit}*/}
                                    {/*       label="Haarfarbe"/>*/}
                                    <Field component={SelectNative}
                                           name='service_employee.hairColor.id'
                                           onBlur={handleSubmit}
                                           label='Haarfarbe'
                                           id={"test"}
                                    >
                                        <option value={1}>blond</option>
                                        <option value={7}>dunkelblond</option>
                                        <option value={3}>hellbraun</option>
                                        <option value={2}>brünett</option>
                                        <option value={4}>dunkelbraun</option>
                                        <option value={5}>schwarz</option>
                                        <option value={6}>rot</option>
                                        <option value={8}>grau</option>
                                    </Field>
                                    <Grid item xs={12}>
                                        <br/>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikSlider} name='service_employee.body_height'
                                           min={150} max={210}
                                           label="Körpergröße" unit="cm"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikSlider} name='service_employee.clothes_size'
                                           min={32} max={62} step={2}
                                           label="Konfektionsgröße"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                {/*<Grid item xs={12}>*/}
                                {/*    <Field component={FormikSlider} name='service_employee.clothes_size'*/}
                                {/*           min={32} max={42} step={2}*/}
                                {/*           label="Konfektionsgröße (Damen)"/>*/}
                                {/*</Grid>*/}
                                <Grid item xs={12}>
                                    <Field component={FormikTshirtSlider} name='service_employee.t_shirt_size'
                                           min={1}
                                           max={7}
                                           valueLabelFormat={tshirtValueLabelFormat}
                                           getAriaValueText={tshirtValueLabelFormat}
                                           aria-labelledby="discrete-slider-restrict"
                                           marks={tshirtMarks}
                                           label="T-Shirt Größe"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikSlider} name='service_employee.jeans_width'
                                           min={26} max={40}
                                           label="Jeans (Weite)"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikSlider} name='service_employee.jeans_length'
                                           min={24} max={38}
                                           label="Jeans (Länge)"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikSlider} name='service_employee.shoe_size'
                                           min={34} max={52} step={0.5}
                                           label="Schuhgröße"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field component={FormikSwitch}
                                           name="service_employee.has_glasses"
                                           onBlur={handleSubmit}/>
                                    <Typography>Bist du Brillenträger?</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikSwitch}
                                           name="service_employee.is_tattooed"
                                           onBlur={handleSubmit}/>
                                    <Typography>Bist du (sichtbar) tätowiert?</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikSwitch}
                                           name="service_employee.is_pierced"
                                           onBlur={handleSubmit}/>
                                    <Typography>Bist du gepierced?</Typography>
                                </Grid>

                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel ref={panel5Ref} square expanded={expanded === 'panel5'} onChange={handlePanelChange('panel5')}>
                        <ExpansionPanelSummary aria-controls="panel5d-content" id="panel5d-header">
                            {expanded === 'panel5' ? <Minus/> : <Plus/>} <Typography
                            className={'PersonalData'}>Qualifikationen</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Field component={FormikSwitch}
                                           name="service_employee.has_driver_licence"
                                           onBlur={handleSubmit}/>
                                    <Typography>Führerschein Klasse B</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikSwitch}
                                           name="service_employee.has_a_security_cert"
                                           onBlur={handleSubmit}/>
                                    <Typography>§34A Schein</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikSwitch}
                                           name="service_employee.has_transport_of_persons_sparkle"
                                           onBlur={handleSubmit}/>
                                    <Typography>Personenbeförderungsschein</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                <br/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikSchoolSlider}
                                           name="service_employee.graduation.id"
                                           min={1}
                                           max={5}
                                           aria-labelledby="discrete-slider-restrict"
                                           label="Schulabschluss"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextInput}
                                           name='service_employee.apprenticeship.subject.subject'
                                           label={'Ausbildung abgeschlossen'}
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikGraduationSlider}
                                           name="service_employee.currentDegree.degree.id"
                                           valueLabelFormat={academicDegreeValueLabelFormat}
                                           getAriaValueText={academicDegreeValueLabelFormat}
                                           aria-labelledby="discrete-slider-restrict"
                                           min={1}
                                           max={6}
                                           valueLabelDisplay="auto"
                                           marks={academicDegreeMarks}
                                           label="Akademischer Grad"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextInput} name='service_employee.finishedDegree.subject.subject'
                                           label={'Studium abgeschlossen'}
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikGraduationSlider}
                                           name="service_employee.finishedDegree.degree.id"
                                           valueLabelFormat={academicDegreeValueLabelFormat}
                                           getAriaValueText={academicDegreeValueLabelFormat}
                                           aria-labelledby="discrete-slider-restrict"
                                           min={1}
                                           max={6}
                                           valueLabelDisplay="auto"
                                           marks={academicDegreeMarks}
                                           label="Akademischer Grad"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextInput} name='service_employee.currentDegree.subject.subject'
                                           label={'Aktuelles Studium'}
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextInput}
                                           name='service_employee.currentDegree.university.university'
                                           label={'Hochschule'}
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikSlider} name="service_employee.currentDegree.semester"
                                           min={1}
                                           max={30}
                                           label="Semesteranzahl"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>

                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel ref={panel6Ref} square expanded={expanded === 'panel6'} onChange={handlePanelChange('panel6')}>
                        <ExpansionPanelSummary aria-controls="panel6d-content" id="panel6d-header">
                            {expanded === 'panel6' ? <Minus/> : <Plus/>} <Typography
                            className={'PersonalData'}>Sprachkenntnisse</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    <FieldArray name="languages"
                                                render={arrayHelpers => {
                                                    return (
                                                        <div className={"language_container"}>
                                                            <RWRoundButton
                                                                variant="outlined"
                                                                color="primary"
                                                                text="Sprache hinzufügen"
                                                                onClick={() => {
                                                                    arrayHelpers.insert(0,{
                                                                        id: -1,
                                                                        language: "",
                                                                        degreeOfKnowledge: {
                                                                            id: 1,
                                                                            knowledge: "Grundkenntnis"
                                                                        }
                                                                    });
                                                                    setTimeout(function() {
                                                                        handleSubmit();
                                                                        setRandom(Math.random());
                                                                    }, 5);
                                                                }
                                                                }/>
                                                            {values.languages.map((language, index) => (
                                                                <div key={index}>
                                                                    <Field component={SelectNative}
                                                                           name={`languages.${index}.id`}
                                                                           onBlur={handleSubmit}
                                                                    >
                                                                        <option value={-1}>auswählen</option>
                                                                        <option value={23}>Deutsch</option>
                                                                        <option value={1}>Englisch</option>
                                                                        <option value={34}>Französisch</option>
                                                                        <option value={51}>Italienisch</option>
                                                                        <option value={27}>Spanisch</option>
                                                                        <option value={137}>Afghanisch</option>
                                                                        <option value={106}>Albanisch</option>
                                                                        <option value={6}>Arabisch</option>
                                                                        <option value={136}>Bosnisch</option>
                                                                        <option value={12}>Bulgarisch</option>
                                                                        <option value={134}>Chinesisch</option>
                                                                        <option value={22}>Dänisch</option>
                                                                        <option value={31}>Finnisch</option>
                                                                        <option value={25}>Griechisch</option>
                                                                        <option value={42}>Hindi</option>
                                                                        <option value={82}>Holländisch</option>
                                                                        <option value={53}>Japanisch</option>
                                                                        <option value={61}>Koreanisch</option>
                                                                        <option value={43}>Kroatisch</option>
                                                                        <option value={72}>Mazedonisch</option>
                                                                        <option value={30}>Persisch</option>
                                                                        <option value={87}>Polnisch</option>
                                                                        <option value={89}>Portugiesisch</option>
                                                                        <option value={93}>Rumänisch</option>
                                                                        <option value={94}>Russisch</option>
                                                                        <option value={111}>Schwedisch</option>
                                                                        <option value={107}>Serbisch</option>
                                                                        <option value={20}>Tschechisch</option>
                                                                        <option value={122}>Türkisch</option>
                                                                        <option value={126}>Ukrainisch</option>
                                                                        <option value={44}>Ungarisch</option>

                                                                    </Field>

                                                                    <Field
                                                                        min={1}
                                                                        max={4}
                                                                        valueLabelFormat={degreeOfKnowledgeValueLabelFormat()}
                                                                        aria-labelledby="discrete-slider-restrict"
                                                                        marks={degreeOfKnowledgeMarks}
                                                                        component={FormikDegreeOfKnowledgeSlider}
                                                                        label="Sprachkenntnis"
                                                                        name={`languages.${index}.degreeOfKnowledge.id`}
                                                                        onBlur={handleSubmit}
                                                                    />
                                                                    <RWRoundButton
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        text={typeof(appData.user.languages[index]) !== "undefined" ? appData.user.languages[index].language + " entfernen" : "Sprache entfernen"}
                                                                        onClick={() => arrayHelpers.remove(index)}/>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )
                                                }
                                                }
                                    />
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel ref={panel7Ref} square expanded={expanded === 'panel7'} onChange={handlePanelChange('panel7')}>
                        <ExpansionPanelSummary aria-controls="panel7d-content" id="panel7d-header">
                            {expanded === 'panel7' ? <Minus/> : <Plus/>} <Typography
                            className={'PersonalData'}>Schlafgelegenheiten</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container>
                                <Grid item xs={6} style={{display: "flex", marginBottom: "0.5em"}}>
                                    <Field component={FormikSwitch}
                                           name={`service_employee.accommodationOptions.${0}.checked`}
                                           onBlur={handleSubmit}/>
                                    <Typography style={{marginLeft: "0.5em"}}> Berlin</Typography>
                                </Grid>
                                <Grid item xs={6} style={{display: "flex", marginBottom: "0.5em"}}>
                                    <Field component={FormikSwitch}
                                           name="service_employee.accommodationOptions[2].checked"
                                           onBlur={handleSubmit}/>
                                    <Typography style={{marginLeft: "0.5em"}}> Düsseldorf</Typography>
                                </Grid>
                                <Grid item xs={6} style={{display: "flex", marginBottom: "0.5em"}}>
                                    <Field component={FormikSwitch}
                                           name="service_employee.accommodationOptions[9].checked"
                                           onBlur={handleSubmit}/>
                                    <Typography style={{marginLeft: "0.5em"}}> Essen</Typography>
                                </Grid>
                                <Grid item xs={6} style={{display: "flex", marginBottom: "0.5em"}}>
                                    <Field component={FormikSwitch}
                                           name="service_employee.accommodationOptions[4].checked"
                                           onBlur={handleSubmit}/>
                                    <Typography style={{marginLeft: "0.5em"}}> Frankfurt a. M.</Typography>
                                </Grid>
                                <Grid item xs={6} style={{display: "flex", marginBottom: "0.5em"}}>
                                    <Field component={FormikSwitch}
                                           name="service_employee.accommodationOptions[3].checked"
                                           onBlur={handleSubmit}/>
                                    <Typography style={{marginLeft: "0.5em"}}> Friedrichshafen</Typography>
                                </Grid>
                                <Grid item xs={6} style={{display: "flex", marginBottom: "0.5em"}}>
                                    <Field component={FormikSwitch}
                                           name="service_employee.accommodationOptions[5].checked"
                                           onBlur={handleSubmit}/>
                                    <Typography style={{marginLeft: "0.5em"}}> Hamburg</Typography>
                                </Grid>
                                <Grid item xs={6} style={{display: "flex", marginBottom: "0.5em"}}>
                                    <Field component={FormikSwitch}
                                           name="service_employee.accommodationOptions[7].checked"
                                           onBlur={handleSubmit}/>
                                    <Typography style={{marginLeft: "0.5em"}}> Hannover</Typography>
                                </Grid>
                                <Grid item xs={6} style={{display: "flex", marginBottom: "0.5em"}}>
                                    <Field component={FormikSwitch}
                                           name="service_employee.accommodationOptions[1].checked"
                                           onBlur={handleSubmit}/>
                                    <Typography style={{marginLeft: "0.5em"}}> Köln</Typography>
                                </Grid>
                                <Grid item xs={6} style={{display: "flex", marginBottom: "0.5em"}}>
                                    <Field component={FormikSwitch}
                                           name="service_employee.accommodationOptions[10].checked"
                                           onBlur={handleSubmit}/>
                                    <Typography style={{marginLeft: "0.5em"}}> München</Typography>
                                </Grid>
                                <Grid item xs={6} style={{display: "flex", marginBottom: "0.5em"}}>
                                    <Field component={FormikSwitch}
                                           name="service_employee.accommodationOptions[8].checked"
                                           onBlur={handleSubmit}/>
                                    <Typography style={{marginLeft: "0.5em"}}> Nürnberg</Typography>
                                </Grid>
                                <Grid item xs={6} style={{display: "flex", marginBottom: "0.5em"}}>
                                    <Field component={FormikSwitch}
                                           name="service_employee.accommodationOptions[6].checked"
                                           onBlur={handleSubmit}/>
                                    <Typography style={{marginLeft: "0.5em"}}> Stuttgart</Typography>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel ref={panel8Ref} square expanded={expanded === 'panel8'} onChange={handlePanelChange('panel8')}>
                        <ExpansionPanelSummary aria-controls="panel8d-content" id="panel8d-header">
                            {expanded === 'panel8' ? <Minus/> : <Plus/>} <Typography
                            className={'PersonalData'}>Arbeitszeiten</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Field component={FormikSwitch}
                                           name="service_employee.prefers_day_shift"
                                           onBlur={handleSubmit}/>
                                    <Typography>Tagschicht</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field component={FormikSwitch}
                                           name="service_employee.prefers_night_shift"
                                           onBlur={handleSubmit}/>
                                    <Typography>Nachtschicht</Typography>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel ref={panel9Ref} square expanded={expanded === 'panel9'} onChange={handlePanelChange('panel9')}>
                        <ExpansionPanelSummary aria-controls="panel9d-content" id="panel9d-header">
                            {expanded === 'panel9' ? <Minus/> : <Plus/>} <Typography
                            className={'PersonalData'}>Messeerfahrung</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Field component={FormikExperienceSlider}
                                           name="service_employee.hostessExperience.value"
                                           min={0}
                                           max={4}
                                           valueLabelFormat={experienceValueLabelFormat}

                                           aria-labelledby="discrete-slider-restrict"
                                           marks={experienceMarks}
                                           label="Hostess | Model"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikExperienceSlider}
                                           name="service_employee.cleaningExperience.value"
                                           min={0}
                                           max={4}
                                           valueLabelFormat={experienceValueLabelFormat}
                                           aria-labelledby="discrete-slider-restrict"
                                           marks={experienceMarks}
                                           label="Reinigung | Event Cleaning"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikExperienceSlider}
                                           name="service_employee.securityExperience.value"
                                           min={0}
                                           max={4}
                                           valueLabelFormat={experienceValueLabelFormat}
                                           aria-labelledby="discrete-slider-restrict"
                                           marks={experienceMarks}
                                           label="Sicherheit [§34A Schein] | Einlasskontrolle"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikExperienceSlider}
                                           name="service_employee.promotionExperience.value"
                                           min={0}
                                           max={4}
                                           valueLabelFormat={experienceValueLabelFormat}
                                           aria-labelledby="discrete-slider-restrict"
                                           marks={experienceMarks}
                                           label="Promotion | Product Explainer"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikExperienceSlider}
                                           name="service_employee.gastroExperience.value"
                                           min={0}
                                           max={4}
                                           valueLabelFormat={experienceValueLabelFormat}
                                           aria-labelledby="discrete-slider-restrict"
                                           marks={experienceMarks}
                                           label="Service | Gastro"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={FormikExperienceSlider}
                                           name="service_employee.salesExperience.value"
                                           min={0}
                                           max={4}
                                           valueLabelFormat={experienceValueLabelFormat}
                                           aria-labelledby="discrete-slider-restrict"
                                           marks={experienceMarks}
                                           label="Verkäufer/in | Einzelhandel"
                                           onBlur={handleSubmit}
                                    />
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    {saving && <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        open={savingFeedbackOpen}
                        autoHideDuration={45000}
                        onClose={handleSavingFeedbackClose}
                    >
                        <FeedbackContentWrapper
                            onClose={handleSavingFeedbackClose}
                            variant="info"
                            message="Die reddworld speichert die Daten..."
                        />
                    </Snackbar>}
                    {savingSuccess && <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        open={savingFeedbackOpen}
                        autoHideDuration={2000}
                        onClose={handleSavingFeedbackClose}
                    >
                        <FeedbackContentWrapper
                            onClose={handleSavingFeedbackClose}
                            variant="success"
                            message="Speichern erfolgreich!"
                        />
                    </Snackbar>}
                    {savingError && <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        open={savingFeedbackOpen}
                        autoHideDuration={8000}
                        onClose={handleSavingFeedbackClose}
                    >
                        <FeedbackContentWrapper
                            onClose={handleSavingFeedbackClose}
                            variant="error"
                            message="Es gab einen Fehler beim Speichern der Daten."
                        />
                    </Snackbar>}

                    {/*{ saving && <SavingIndicator /> }*/}
                </Form>
            )}
        />


    )
}

export const SavingIndicator = () => (
    <>
        <SaveIcon/>

    </>
);
