import React, {createContext} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {getSnapshot} from "mobx-state-tree";
import appData from './models/ReddworldData'
import makeInspectable from "mobx-devtools-mst"

// makes the MobX State Tree inspectable for the browser devtools.
makeInspectable(appData);
// createContext makes the data in the store globally available with React Context.
// Every Component wrapped in a <GlobalStore.Provider> Component can
// access the appData, write to it, listen for changes and make calls to the backend.
const GlobalStore = createContext(appData);

//fix hot reloading for mobx modifications
function renderApp() {
    ReactDOM.render(<GlobalStore.Provider><App/></GlobalStore.Provider>, document.getElementById('root'));
}

renderApp();

if (module.hot) {
    module.hot.accept(["./App"], () => {
        //new components
        renderApp()
    });

    module.hot.accept(["./models/ReddworldData"], () => {
        //new model definitions
        const snapshot = getSnapshot(appData);
        appData = appData.create(snapshot);
        renderApp()
    })
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
