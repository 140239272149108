import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {API_BASE, API_FILEDOWNLOAD, API_FILEUPLOAD} from "../../../constants/api";
import {
    IconDeleteFile,
    IconDownloadFile,
    IconOkayForFiles,
    IconPendingForFiles,
    IconRejectedForFiles
} from "../../icons/StatusIcons";
import {
    BILLING_DATA_FILE_DELETED,
    BILLING_DATA_FILE_NOT_UPLOADED_YET,
    BILLING_DATA_FILE_OUTDATED,
    BILLING_DATA_FILE_PENDING,
    BILLING_DATA_FILE_REJECTED,
    BILLING_DATA_FILE_VERIFIED
} from "../../../constants/constants";
import {CloudUploadOutlined} from '@material-ui/icons';
import Dropzone from 'react-dropzone-uploader';
import * as moment from 'moment';

const useStyles = makeStyles({
    centerGrid: {
        textAlign: "center",
    },
    filePreview: {
        margin: "0 auto",
        width: 96,
        height: 96,
        border: "5px solid #b4233c",
        overflowX: "hidden",
        overflowY: "hidden"
    },
    previewCircle: {
        margin: "0 auto",
        width: 112,
        height: 112,
        border: "5px solid #b4233c",
        "border-radius": "50%",
        overflowX: "hidden",
        overflowY: "hidden"
    }
});

export function FilePreviewUploader(fileObj) {
    // holds a random variable, for forcing a rerender of the component
    // eslint-disable-next-line no-unused-vars
    const [state, setState] = React.useState(null);

    let file;

    if (typeof fileObj.file === "undefined" || fileObj.file === null) {
        file = {
            "title": "",
            "filename": "",
            "path": "",
            "date": new Date(),
            "type": "",
            "fileType": {
                "id": 16,
                "type": "Optionale Bescheinigung"
            },
            "status": BILLING_DATA_FILE_NOT_UPLOADED_YET,
        }
    } else {
        file = fileObj.file
    }

    const deleteFile = () => {
        fileObj.file.deleteFileInDB().then(function (values) {
            console.log("success deleting file")
        }, function (values) {
            console.log("error")
        });
    };

    const updateFile = (file) => {
        fileObj.file.updateFile(file);
    };

    let fileInfo = file;

    const getUploadParams = ({file, meta}) => {
        const body = new FormData();
        body.append("action", "uploadBillingDataFilesReact");
        body.append("auth_token", localStorage.getItem("jwt"));
        body.append("fileField", file);
        body.append("fileInfo", JSON.stringify(fileInfo));
        return {
            url: API_FILEUPLOAD,
            body
        }
    };

    const handleChangeStatus = (fileWithMeta, status) => {
        if (status === 'headers_received') {
            console.log("uploaded!");
        } else if (status === 'aborted') {
            console.log("upload failed...");
        } else if (status === 'done') {
            console.log("upload done.");
            let response = JSON.parse(fileWithMeta.xhr.response)[0].data[0];
            updateFile(response);
            // forces rerender of the component
            setState(Math.random());
            console.log(response);
        }
    };

    const classes = useStyles();

    // eslint-disable-next-line no-unused-vars
    let imgSrc = API_BASE + "/img/icons/rw-icon-data_upload-thumbnail.svg";

    switch (file.type) {
        case "application/pdf":
            // imgSrc = ...
            break;
        case "image/jpeg":
            imgSrc = API_BASE + file.path + file.filename;
            break;
        default:
            //imgSrc = API_BASE + "/img/icons/rw-icon-data_upload-thumbnail.svg";
            imgSrc = 'https://wiki.tripwireinteractive.com/images/4/47/Placeholder.png';

    }

    let icon = <IconPendingForFiles/>;
    let buttons = <></>;
    let caption, captionLineTwo;

    /*1    Studienbescheinigung (%semester%)
    2    Schulbescheinigung
    3    Krankenkassen Mitgliedsbescheinigung
    6    Statusschreiben
    7    Meldung zur Sozialversicherung
    8    letzte Gehaltsabrechnung
    11    Anmeldung Hochschule
    12    Gewerbeanmeldung
    13    Personalbogen
    14    Gehaltsabrechnung
    15    Abrechnungsdokument
    16    Optionale Bescheinigung*/


    switch (file.fileType.id) {
        case 1:
            caption = "Studienbescheinigung " + file.semester + " " + file.year;
            captionLineTwo = <></>;
            break;
        case 14:
            caption = "Gehaltsabrechnung";
            // captionLineTwo = <><br />{new Date(file.date).toLocaleDateString()}</>;
            captionLineTwo = <><br/>{moment(file.date).format("DD.MM.YY")}</>;
            break;
        default:
            caption = file.fileType.type;
            captionLineTwo = <></>;
    }

    switch (file.status) {
        case BILLING_DATA_FILE_PENDING:
            icon = <IconPendingForFiles/>;
            buttons = <>
                <div onClick={deleteFile}><IconDeleteFile/></div><div style={{width: "10px"}}> </div>
                <a rel="noopener noreferrer" target="_blank"
                   href={API_FILEDOWNLOAD + '?filename=' + fileObj.file.filename + '&auth_token=' + localStorage.getItem('jwt')}><IconDownloadFile/></a></>;
            break;
        case BILLING_DATA_FILE_VERIFIED:
            icon = <IconOkayForFiles/>;
            buttons = <>
                <a rel="noopener noreferrer" target="_blank"
                   href={API_FILEDOWNLOAD + '?filename=' + fileObj.file.filename + '&auth_token=' + localStorage.getItem('jwt')}><IconDownloadFile/></a>
            </>;
            break;
        case BILLING_DATA_FILE_REJECTED:
            icon = <IconRejectedForFiles/>;
            buttons = <>
                <div onClick={deleteFile}><IconDeleteFile/></div><div style={{width: "10px"}}> </div>
                <a rel="noopener noreferrer" target="_blank"
                   href={API_FILEDOWNLOAD + '?filename=' + fileObj.file.filename + '&auth_token=' + localStorage.getItem('jwt')}><IconDownloadFile/></a></>;
            break;
        case BILLING_DATA_FILE_DELETED:
            icon = <IconRejectedForFiles/>;
            buttons = <>
                <div onClick={deleteFile}><IconDeleteFile/></div><div style={{width: "10px"}}> </div>
                <a rel="noopener noreferrer" target="_blank"
                   href={API_FILEDOWNLOAD + '?filename=' + fileObj.file.filename + '&auth_token=' + localStorage.getItem('jwt')}><IconDownloadFile/></a></>;
            break;
        case BILLING_DATA_FILE_OUTDATED:
            icon = <IconPendingForFiles/>;
            buttons = <>
                <div onClick={deleteFile}><IconDeleteFile/></div><div style={{width: "10px"}}> </div>
                <a rel="noopener noreferrer" target="_blank"
                   href={API_FILEDOWNLOAD + '?filename=' + fileObj.file.filename + '&auth_token=' + localStorage.getItem('jwt')}><IconDownloadFile/></a></>;
            break;
        default:
            icon = <CloudUploadOutlined style={{width: "96px", height: "96px", color: "grey"}}/>;

    }

    return (<>

            <Grid container justify="center" alignItems="center" className={classes.centerGrid}>
                {/*<Grid item xs={12}>*/}
                {/*{caption}<br/>*/}
                {/*</Grid>*/}
                <Grid item xs={12}>
                    <div className={classes.previewCircle}>
                        {icon}
                        <Dropzone
                            getUploadParams={getUploadParams}
                            onChangeStatus={handleChangeStatus}
                            maxFiles={1}
                            canCancel={false}
                            multiple={false}
                            accept="image/*,audio/*,video/*,.pdf"
                            inputContent={(files, extra) => (extra.reject ? '' : '')}
                            PreviewComponent={Preview}
                            disabled={fileObj.uploadDisabled === true}
                            styles={{
                                dropzone: {
                                    overflow: "hidden",
                                    width: 144,
                                    height: 144,
                                    border: "0px",
                                    top: "-140px",
                                    borderRadius: "50%"
                                },

                                border: "0px",
                                "border-radius": "50%",
                                dropzoneActive: {borderColor: 'green'},
                                "z-index": 20,
                                position: "relative",
                                top: "-128px",
                                dropzoneReject: {borderColor: 'red', backgroundColor: '#DAA'},
                                inputLabel: (files, extra) => (extra.reject ? {color: 'red'} : {})
                            }}
                        />
                    </div>
                    {caption}
                    {captionLineTwo}
                </Grid>
                <Grid item xs={12} style={{display: 'contents'}}>
                    {buttons}
                </Grid>
                {/*<Grid item xs={12}>*/}
                {/*    <RWRoundButton variant="outlined" color="primary" text="Upload"/>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*    <Typography>*/}
                {/*         <br/> {file.title}*/}
                {/*        /!*{file.fileType.type} {file.year} <br /> {file.title}*!/*/}
                {/*    </Typography>*/}
                {/*</Grid>*/}
            </Grid>
        </>

    );
}

const Preview = ({meta}) => {
    //const {name, percent, status} = meta;
    return (<></>
        //<span style={{ alignSelf: 'flex-start', margin: '10px 3%', fontFamily: 'Helvetica' }}>
        //{name}, {Math.round(percent)}%, {status}
        //</span>
    )
};
